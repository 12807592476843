import React, { useState } from "react";
import { Card, Button } from "react-bootstrap";
import axios from "axios";
import Swal from "sweetalert2";
import { Base_Url } from "../../../misc";

// InputField Component
const InputField = ({ label, name, value, onChange, placeholder, type = "text" }) => (
  <div className="form-group mb-3 row">
    <label className="col-lg-4 col-form-label">
      {label} <span className="text-danger">*</span>
    </label>
    <div className="col-lg-6">
      <input
        type={type}
        className="form-control"
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
      />
    </div>
  </div>
);

const StyleThirteen = () => {
  const [disableSave, setDisableSave] = useState(true);
  const [layoutSection, setLayoutSection] = useState({
    sectionTitle: "",
    bannerImage: [{ url: "" }], // Initialize as an array
    displayLimit: "",
  });

  // Handle input change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setLayoutSection((prev) => ({
      ...prev,
      [name]: value,
    }));

    setDisableSave(
      !(
        layoutSection.sectionTitle &&
        layoutSection.bannerImage.every((img) => img.url.trim() !== "") &&
        layoutSection.displayLimit
      )
    );
  };

  // Handle banner image URL change
  const handleBannerImageChange = (index, value) => {
    const updatedBannerImages = [...layoutSection.bannerImage];
    updatedBannerImages[index].url = value;
    setLayoutSection((prev) => ({
      ...prev,
      bannerImage: updatedBannerImages,
    }));

    setDisableSave(
      !(
        layoutSection.sectionTitle &&
        updatedBannerImages.every((img) => img.url.trim() !== "") &&
        layoutSection.displayLimit
      )
    );
  };

  // Add a new banner image input
  const addBannerImage = () => {
    setLayoutSection((prev) => ({
      ...prev,
      bannerImage: [...prev.bannerImage, { url: "" }],
    }));
  };

  // Remove a banner image input
  const removeBannerImage = (index) => {
    const updatedBannerImages = layoutSection.bannerImage.filter((_, i) => i !== index);
    setLayoutSection((prev) => ({
      ...prev,
      bannerImage: updatedBannerImages,
    }));

    setDisableSave(
      !(
        layoutSection.sectionTitle &&
        updatedBannerImages.every((img) => img.url.trim() !== "") &&
        layoutSection.displayLimit
      )
    );
  };

  // Submit Data
  const handleSave = () => {
    const payload = {
      sectionTitle1: layoutSection.sectionTitle,
      sectionStyle: "Style13",
      bannerImage: layoutSection.bannerImage,
      status: true,
      displayLimit: layoutSection.displayLimit,
    };

    axios
      .post(`${Base_Url}/create-home-page-sections`, payload)
      .then(() => {
        Swal.fire({
          icon: "success",
          title: "Promotional Banner Created Successfully",
          showConfirmButton: false,
          timer: 1500,
        });
      })
      .catch((error) => {
        console.error("Error saving banner:", error);
      });
  };

  return (
    <div>
      <h4>Promotional Banner (Style Thirteen)</h4>
      <Card>
        <Card.Header>
          <Card.Title>Banner Details</Card.Title>
        </Card.Header>
        <Card.Body>
          <InputField
            label="Section Title"
            name="sectionTitle"
            placeholder="Enter Section Title"
            value={layoutSection.sectionTitle}
            onChange={handleInputChange}
          />
          <div className="mb-3">
            <label>Banner Images</label>
            {layoutSection.bannerImage.map((img, index) => (
              <div key={index} className="d-flex align-items-center mb-2">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Banner Image URL"
                  value={img.url}
                  onChange={(e) => handleBannerImageChange(index, e.target.value)}
                />
                <Button
                  variant="danger"
                  className="ms-2"
                  onClick={() => removeBannerImage(index)}
                  disabled={layoutSection.bannerImage.length === 1} // Disable if only one input remains
                >
                  Remove
                </Button>
              </div>
            ))}
            <Button variant="success" onClick={addBannerImage}>
              Add Banner Image
            </Button>
          </div>
          <InputField
            label="Display Limit"
            name="displayLimit"
            placeholder="Enter Display Limit"
            value={layoutSection.displayLimit}
            onChange={handleInputChange}
            type="number"
          />
        </Card.Body>
      </Card>

      <Button disabled={disableSave} onClick={handleSave} className="mt-3">
        Save
      </Button>
    </div>
  );
};

export default StyleThirteen;
