import React from "react";
import { useFormContext, Controller } from "react-hook-form";

const OSelect = ({
  name,
  options = [],
  id,
  label,
  required = false,
  className = "",
  placeholder = "Select an option",
  defaultValue = "",
}) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const errorMessage = errors[name]?.message;

  return (
    <div className={`form-group mb-3 ${className}`}>
      {label && (
        <label htmlFor={id || name} className="form-label">
          {required && <span style={{ color: "red" }}>*</span>} {label}
        </label>
      )}

      {
        console.log(defaultValue)
      }
      <Controller
        control={control}
        name={name}
        defaultValue={defaultValue}
        render={({ field }) => (
          <select
            {...field}
            id={id || name}
            className={`form-control ${errors[name] ? "is-invalid" : ""}`}
            required={required}
          >
            <option value="" disabled>
              {placeholder}
            </option>
            {options.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        )}
      />
      {errorMessage && (
        <div className="invalid-feedback">{errorMessage}</div>
      )}
    </div>
  );
};

export default OSelect;
