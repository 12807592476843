import React, { useState, useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { useParams } from "react-router-dom";
import FormProviderCustom from "../../../ReuseableContainer/Form/FormProvider";
import OInput from "../../../ReuseableContainer/Field/OInput";
import OImageField from "../../../ReuseableContainer/Field/OImageField";
import { Base_Url } from "../../../misc";
import axios from "axios";
import OSelect from "../../../ReuseableContainer/Field/OSelect";
import Swal from "sweetalert2";

const SellerViewEditPage = () => {
    const params = useParams();
    const [isEditing, setIsEditing] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [sellerData, setSellerData] = useState(null);
    const methods = useForm();
    const [profileImage, setProfileImage] = useState(null);
    const [bankList, setBankList] = useState()
    const [mobileBankList, setMobileBankList] = useState()
    const [newBankAccount, setNewBankAccount] = useState(false);
    const [newMobileBanking, setNewMobileBanking] = useState(false);
    useEffect(() => {
        const fetchSellerData = async () => {
            try {
                setIsLoading(true);
                const response = await fetch(
                    `${Base_Url}/get-single-become-seller/${params.id}`
                );
                const data = await response.json();
                console.log(data);

                if (data.status == "Success") {
                    setSellerData(data.data[0]);
                    methods.reset(data);
                } else {
                    setError("Failed to load seller data");
                }
            } catch (error) {
                setError("Error fetching seller data");
                console.error("Error fetching seller data:", error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchSellerData();
        getBankInfo()
        getMobileBankInfo()
    }, [params.id]);

    const getBankInfo = async () => {
        const res = await axios.get(`${Base_Url}/get-banklist?type=${"Bank"}`,

        )
        const formattedBankList = res.data.data.map(bank => ({
            label: bank.bankName, // Assuming `name` is the bank name in the API response
            value: bank.bankName    // Assuming `id` is the unique identifier for the bank
        }));

        setBankList(formattedBankList)
        console.log(res);

    }
    const getMobileBankInfo = async () => {
        const res = await axios.get(`${Base_Url}/get-banklist?type=${"mobileBanking"}`,

        )
        const formattedBankList = res.data.data.map(bank => ({
            label: bank.bankName, // Assuming `name` is the bank name in the API response
            value: bank.bankName    // Assuming `id` is the unique identifier for the bank
        }));

        setMobileBankList(formattedBankList)
        console.log(res);

    }

    const handleAddNewBank = () => {
        setNewBankAccount(true);
    };

    const handleAddNewMobileBanking = () => {
        setNewMobileBanking(true);
    };


    const handleSave = async (data) => {
        console.log(data);
        if (newBankAccount && data.newBank) {
            const newBankDetails = [...(sellerData.bankDetails || []), data.newBank];
            data.bankDetails = newBankDetails;
        }

        // If there's a new mobile banking account, append it to existing mobile banking
        if (newMobileBanking && data.newMobileBanking) {
            const newMobileBankingDetails = [...(sellerData.mobileBanking || []), data.newMobileBanking];
            data.mobileBanking = newMobileBankingDetails;
        }

        try {
            const response = await fetch(`${Base_Url}/update-seller-user/${params.id}`, {
                method: "POST",
                body: JSON.stringify(data),
                headers: {
                    "Content-Type": "application/json",
                },
            });
            console.log(response);
            Swal.fire({
                icon: "success",
                title: "Data updated!!",
                timer: "2000"
            })
            if (response.ok) {
                // Show success toast/alert
                setIsEditing(false);
            }
        } catch (error) {
            // Show error toast/alert
            console.error("Error saving data:", error);
        }
    };
    const handleProfileImageChange = async (file) => {
        console.log(file);

        try {
            // Create a new FormData instance
            const formData = new FormData();

            // Append the file to the formData object with the key 'image'
            formData.append('image', file);

            // Send the formData with the file to the backend using axios
            const response = await axios.post(`${Base_Url}/imguploads`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data', // Ensure this header is set for file uploads
                },
            });
            console.log(response.data.images);


            const data = {
                user: {
                    userProfilePhoto: response.data.images
                }
            }

            const update = await axios.post(`${Base_Url}/update-seller-user/${params.id}`, data)

            console.log(update);
            Swal.fire({
                icon: "success",
                title: "Image uploaded",
                timer: "2000"
            })

        } catch (error) {
            console.error('Error uploading image:', error);
        }
    };
    const handleKitchenImageChange = async (file) => {
        try {
            // Create a new FormData instance
            const formData = new FormData();

            // Append the file to the formData object with the key 'image'
            formData.append('image', file);

            // Send the formData with the file to the backend using axios
            const response = await axios.post(`${Base_Url}/imguploads`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data', // Ensure this header is set for file uploads
                },
            });
            console.log(response.data.images);


            const data = {
                sellerProfilePhoto: response.data.images

            }

            const update = await axios.post(`${Base_Url}/update-seller-user/${params.id}`, data)

            console.log(update);
            Swal.fire({
                icon: "success",
                title: "Image uploaded",
                timer: "2000"
            })

        } catch (error) {
            console.error('Error uploading image:', error);
        }

    }

    const handleIdentityImageChange = async (file) => {
        try {
            const formData = new FormData();
            formData.append('image', file);

            const response = await axios.post(`${Base_Url}/imguploads`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data', // Ensure this header is set for file uploads
                },
            });

            console.log(response.data.images);

            const data = {
                user: {
                    Identity: [
                        { Img: response.data.images }
                    ],
                },
            };


            const update = await axios.post(`${Base_Url}/update-seller-user/${params.id}`, data);

            console.log(update.data);
            Swal.fire({
                icon: "success",
                title: "Image uploaded",
                timer: "2000"
            })

        } catch (error) {
            console.error('Error uploading image or updating user:', error.response?.data || error.message);
        }
    };


    const handleProfileImageRemove = () => {
        setProfileImage(null);
    };


    if (isLoading) {
        return (
            <div className="d-flex justify-content-center align-items-center min-vh-100">
                <div className="spinner-border text-primary" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
        );
    }




    //   if (error) {
    //     return (
    //       <div className="alert alert-danger m-4" role="alert">
    //         {error}
    //       </div>
    //     );
    //   }
    console.log(sellerData);

    return (
        <div className="container-fluid py-4">
            <div className="card shadow-sm">
                <div className="card-header bg-white py-3">
                    <div className="d-flex justify-content-between align-items-center">
                        <div>
                            <h4 className="mb-0">{isEditing ? "Edit Seller Profile" : "Seller Profile"}</h4>
                            <span className={`badge ${sellerData?.sellerApproval === 'Pending' ? 'bg-warning' : 'bg-success'}`}>
                                {sellerData?.sellerApproval}
                            </span>
                        </div>
                        <button
                            className={`btn ${isEditing ? "btn-outline-secondary" : "btn-primary"}`}
                            onClick={() => setIsEditing(!isEditing)}
                        >
                            {isEditing ? "Cancel Editing" : "Edit Profile"}
                        </button>
                    </div>
                </div>

                <div className="card-body">
                    <FormProviderCustom submitHandler={handleSave}>
                        {/* Personal Information Section */}
                        <section className="mb-5">
                            <h5 className="border-bottom pb-2 mb-4">Personal Information</h5>
                            <div className="row">
                                <div className="col-12 mb-4 text-center">
                                    <OImageField
                                        onFileChange={handleProfileImageChange}
                                        onRemove={handleProfileImageRemove}
                                        name="userProfilePhoto"
                                        value={sellerData.userData[0]?.userProfilePhoto[0]?.orginalImageUrl}
                                        disabled={!isEditing}
                                        isEditable={isEditing}

                                    />
                                </div>
                                <div className="col-md-6 mb-3">
                                    <OInput
                                        name="user.userFullName"
                                        label="Full Name"
                                        value={!isEditing ? sellerData.userData[0]?.userFullName : undefined}
                                        defaultValue={sellerData.userData[0]?.userFullName}
                                        disabled={!isEditing}
                                    />
                                </div>
                                <div className="col-md-6 mb-3">
                                    <OInput
                                        name="user.userMobileNo"
                                        label="Mobile Number"
                                        value={!isEditing ? sellerData.userData[0]?.userMobileNo : undefined}
                                        defaultValue={sellerData.userData[0]?.userMobileNo}
                                        disabled={!isEditing}
                                    />
                                </div>
                                <div className="col-md-6 mb-3">
                                    <OInput
                                        name="user.email"
                                        label="Email"
                                        value={!isEditing ? sellerData.userData[0]?.email : undefined}
                                        defaultValue={sellerData.userData[0]?.email}
                                        disabled={!isEditing}
                                    />
                                </div>

                                <div className="col-md-6 mb-3">
                                    <OInput
                                        name="user.address"
                                        label="Address"
                                        value={!isEditing ? sellerData.userData[0]?.address : undefined}
                                        defaultValue={sellerData.userData[0]?.address}
                                        disabled={!isEditing}
                                    />
                                </div>


                                <div className="col-md-6 mb-3">
                                    <OInput
                                        name="user.Identity[0].Number"
                                        label={`${sellerData.userData?.[0]?.Identity?.[0]?.type || ""} Number`}
                                        value={!isEditing ? sellerData.userData[0]?.Identity[0]?.Number : undefined}
                                        defaultValue={sellerData.userData[0]?.Identity[0]?.Number}
                                        disabled={!isEditing}
                                    />
                                </div>

                                <div className="col-md-6 mb-3">
                                    <OImageField
                                        onFileChange={handleIdentityImageChange}
                                        onRemove={handleProfileImageRemove}
                                        name="userProfilePhoto"
                                        value={sellerData.userData[0]?.Identity[0]?.Img[0]?.orginalImageUrl}
                                        disabled={!isEditing}
                                        isEditable={isEditing}

                                    />
                                </div>
                            </div>
                        </section>



                        {/* Kitchen Information Section */}
                        <section className="mb-5">
                            <h5 className="border-bottom pb-2 mb-4">Kitchen Information</h5>
                            <div className="row">
                                <div className="col-12 mb-4 text-center">
                                    <OImageField
                                        onFileChange={handleKitchenImageChange}
                                        name="sellerProfilePhoto"
                                        value={sellerData.sellerProfilePhoto[0]?.orginalImageUrl}
                                        disabled={!isEditing}
                                        isEditable={isEditing}
                                    />
                                </div>
                                <div className="col-md-6 mb-3">
                                    <OInput
                                        name="kitchenName"
                                        label="Kitchen Name"
                                        defaultValue={sellerData.kitchenName}
                                        value={!isEditing ? sellerData.kitchenName : undefined}
                                        disabled={!isEditing}
                                    />
                                </div>
                                <div className="col-12 mb-3">
                                    <OInput
                                        name="address"
                                        label="Address"
                                        value={!isEditing ? sellerData.address : undefined}
                                        defaultValue={sellerData.address}
                                        disabled={!isEditing}
                                    />
                                </div>

                                <div className="col-md-6 mb-3">
                                    <OInput
                                        name="policeStation"
                                        label="Police Station"
                                        value={!isEditing ? sellerData.policeStation : undefined}

                                        defaultValue={sellerData.policeStation}
                                        disabled={!isEditing}
                                    />
                                </div>
                            </div>


                        </section>

                        {/* Bank Details Section */}
                        <section className="mb-5">
                            <h5 className="border-bottom pb-2 mb-4">Bank Details</h5>
                            {
                                sellerData?.bankDetails?.length > 0 && <div className="row">
                                    {sellerData.bankDetails.map((bank, index) => (
                                        <React.Fragment key={bank._id}>
                                            <div className="col-md-6 mb-3">

                                                <OSelect name={`bankDetails[${index}].bankName`}
                                                    label="Bank Name"
                                                    options={bankList}
                                                    defaultValue={bank.bankName}
                                                />
                                                {/* <OInput
                                                name={`bankDetails[${index}].bankName`}
                                                label="Bank Name"
                                                defaultValue={bank.bankName}
                                                disabled={!isEditing}
                                            />  */}
                                            </div>
                                            <div className="col-md-6 mb-3">

                                                <OInput
                                                    name={`bankDetails[${index}].bankAccName`}
                                                    label="Account Name"
                                                    value={!isEditing ? bank.bankAccName : undefined}
                                                    defaultValue={bank.bankAccName}
                                                    disabled={!isEditing}
                                                />
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <OInput
                                                    name={`bankDetails[${index}].bankAccNo`}
                                                    label="Account Number"
                                                    value={!isEditing ? bank.bankAccNo : undefined}

                                                    defaultValue={bank.bankAccNo}
                                                    disabled={!isEditing}
                                                />
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <OInput
                                                    name={`bankDetails[${index}].bankRoutingNo`}
                                                    label="Routing Number"
                                                    value={!isEditing ? bank.bankRoutingNo : undefined}

                                                    defaultValue={bank.bankRoutingNo}
                                                    disabled={!isEditing}
                                                />
                                            </div>
                                        </React.Fragment>
                                    ))}
                                </div>
                            }
                            {isEditing && (
                                <button
                                    type="button"
                                    className="btn btn-outline-primary mt-3"
                                    onClick={handleAddNewBank}
                                >
                                    + Add New Bank Account
                                </button>
                            )}

                            {isEditing && newBankAccount && (
                                <div className="row mt-4">
                                    <h6 className="mb-3">New Bank Account</h6>
                                    <div className="col-md-6 mb-3">
                                        <OSelect
                                            name="newBank.bankName"
                                            label="Bank Name"
                                            options={bankList}
                                        />
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <OInput
                                            name="newBank.bankAccName"
                                            label="Account Name"
                                        />
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <OInput
                                            name="newBank.bankAccNo"
                                            label="Account Number"
                                        />
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <OInput
                                            name="newBank.bankRoutingNo"
                                            label="Routing Number"
                                        />
                                    </div>
                                </div>
                            )}


                            {
                                sellerData?.mobileBanking?.length > 0 && <div className="row">
                                    {sellerData.mobileBanking.map((bank, index) => (
                                        <React.Fragment key={bank._id}>

                                            <div className="col-md-6 mb-3">


                                                {
                                                    !isEditing && <OInput label={`Mobile Bank Name`} name={`bankName`} value={bank.operatorName} />
                                                }

                                                {isEditing && <OSelect name={`mobileBanking[${index}].operatorName`}
                                                    label="Bank Name"
                                                    nam
                                                    options={mobileBankList}
                                                    defaultValue={bank.operatorName}
                                                />}

                                            </div>
                                            <div className="col-md-6 mb-3">

                                                <OInput
                                                    name={`mobileBanking[${index}].mobileNumber`}
                                                    label="Mobile Number"
                                                    value={!isEditing ? bank.mobileNumber : undefined}
                                                    defaultValue={bank.mobileNumber}
                                                    disabled={!isEditing}
                                                />
                                            </div>


                                        </React.Fragment>
                                    ))}
                                    {isEditing && newMobileBanking && (
                                        <div className="row mt-4">
                                            <h6 className="mb-3">New Mobile Banking Account</h6>
                                            <div className="col-md-6 mb-3">
                                                <OSelect
                                                    name="newMobileBanking.operatorName"
                                                    label="Mobile Bank Name"
                                                    options={mobileBankList}
                                                />
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <OInput
                                                    name="newMobileBanking.mobileNumber"
                                                    label="Mobile Number"
                                                />
                                            </div>
                                        </div>
                                    )}

                                    {/* Add New Mobile Banking Button */}
                                    {isEditing && !newMobileBanking && (
                                        <button
                                            type="button"
                                            className="btn btn-outline-primary mt-3 ms-2"
                                            onClick={handleAddNewMobileBanking}
                                        >
                                            + Add New Mobile Banking
                                        </button>
                                    )}
                                </div>
                            }

                        </section>

                        {isEditing && (
                            <div className="d-flex justify-content-end mt-4">
                                <button type="submit" className="btn btn-success">
                                    Save Changes
                                </button>
                            </div>
                        )}
                    </FormProviderCustom>
                </div>
            </div>
        </div>

    );
};

export default SellerViewEditPage;