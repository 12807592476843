import React, { useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';

const FormProviderCustom = ({ children, submitHandler, defaultValues }) => {
    // Configure the form with default values if provided
    const formConfig = defaultValues ? { defaultValues } : {};
    const methods = useForm(formConfig);
    const { handleSubmit, reset } = methods;

    // Submit handler that calls the provided handler and resets the form
    const onSubmit = (data) => {
        submitHandler(data);
        reset();
    };

    // Update form values when defaultValues changes
    useEffect(() => {
        if (defaultValues) {
            reset(defaultValues);
        }
    }, [defaultValues, reset]);

    return (
        <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
                {children}
            </form>
        </FormProvider>
    );
};

export default FormProviderCustom;
