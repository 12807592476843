import React, { useState, useEffect } from "react";
import { Col, Row, Card, Button } from "react-bootstrap";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import axios from "axios";
import { Base_Url } from "../../../misc";
import Swal from "sweetalert2";

// InputField Component
const InputField = ({ label, name, value, onChange, placeholder }) => (
    <div className="form-group mb-3 row">
        <label className="col-lg-4 col-form-label">
            {label} <span className="text-danger">*</span>
        </label>
        <div className="col-lg-6">
            <input
                type="text"
                className="form-control"
                name={name}
                placeholder={placeholder}
                value={value}
                onChange={onChange}
            />
        </div>
    </div>
);
const animatedComponents = makeAnimated();

// MultiSelect Component
const MultiSelect = ({ label, options, value, onChange }) => {
    return (
        <div className="form-group mb-3 row">
            <label className="col-lg-4 col-form-label">
                {label} <span className="text-danger">*</span>
            </label>
            <div className="col-lg-6">
                <Select
                    closeMenuOnSelect={false}
                    components={animatedComponents}
                    isMulti
                    options={options}
                    value={value}
                    onChange={onChange}
                    classNamePrefix="select"
                />
            </div>
        </div>
    );
};

const StyleTwelve = () => {
    const [categoryOptions, setCategoryOptions] = useState([]);
    const [disableSave, setDisableSave] = useState(true);

    const [layoutSection, setLayoutSection] = useState({
        sectionTitle: "",
        categories: [],
        foodTypes: [],
        orderBy: "",
        displayLimit: "",
        sectionCardColor: "" // Added field for card color
    });

    const foodTypeOptions = [
        { value: "INSTANT", label: "INSTANT" },
        { value: "PREORDER", label: "PREORDER" },
        { value: "CATERING", label: "CATERING" },
        { value: "READYMADE", label: "READYMADE" },
        { value: "ALL", label: "ALL" },
    ];

    const cardColorOptions = [
        { value: "MULTICOLOR", label: "MULTICOLOR" },
        { value: "WHITE", label: "FLAT" },
        // { value: "FLAT", label: "FLAT" },
    ];

    // Fetch categories from the API
    const fetchCategories = async () => {
        try {
            const response = await axios.get(`${Base_Url}/get-category`);
            const formattedCategories = response.data.data.map((cat) => ({
                value: cat._id,
                label: cat.categoryName,
            }));
            setCategoryOptions(formattedCategories);
        } catch (error) {
            console.error("Error fetching categories:", error);
        }
    };

    useEffect(() => {
        fetchCategories();
    }, []);

    // Handle input change
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setLayoutSection((prev) => ({
            ...prev,
            [name]: value,
        }));

        setDisableSave(
            !(
                layoutSection.sectionTitle &&
                layoutSection.orderBy &&
                layoutSection.displayLimit
            )
        );
    };

    // Handle MultiSelect changes
    const handleMultiSelectChange = (key, value) => {
        setLayoutSection((prev) => ({
            ...prev,
            [key]: value,
        }));
    };

    // Handle card color selection
    const handleCardColorChange = (selectedOption) => {
        setLayoutSection((prev) => ({
            ...prev,
            sectionCardColor: selectedOption,
        }));
    };

    // Submit Data
    const handleSave = () => {
        const payload = {
            sectionTitle1: layoutSection.sectionTitle,
            sectionStyle: "Style12",
            sectionCategoriesColor: ["black"],
            orderBy: layoutSection.orderBy,
            status: true,
            displayLimit: layoutSection.displayLimit,
            sectionCategories1: layoutSection.categories,
            sectionFoodTypeCategories1: layoutSection.foodTypes,
            sectionCardColor: layoutSection.sectionCardColor?.value, // Send the selected card color
        };

        console.log(payload,layoutSection.sectionCardColor)

        axios
            .post(`${Base_Url}/create-home-page-sections`, payload)
            .then(() => {
                Swal.fire({
                    icon: "success",
                    title: "Style Created Successfully",
                    showConfirmButton: false,
                    timer: 1500,
                });
            })
            .catch((error) => {
                console.error("Error saving layout:", error);
            });
    };

    return (
        <div>
            <h4>Design Section (Single Layout)</h4>
            <Card>
                <Card.Header>
                    <Card.Title>Section Layout</Card.Title>
                </Card.Header>
                <Card.Body>
                    <InputField
                        label="Section Title"
                        name="sectionTitle"
                        placeholder="Enter Section Title"
                        value={layoutSection.sectionTitle}
                        onChange={handleInputChange}
                    />
                    <MultiSelect
                        label="Section Categories"
                        options={categoryOptions}
                        value={layoutSection.categories}
                        onChange={(value) => handleMultiSelectChange("categories", value)}
                    />
                    <MultiSelect
                        label="Food Types"
                        options={foodTypeOptions}
                        value={layoutSection.foodTypes}
                        onChange={(value) => handleMultiSelectChange("foodTypes", value)}
                    />
                    <InputField
                        label="Order By"
                        name="orderBy"
                        placeholder="Enter Order By"
                        value={layoutSection.orderBy}
                        onChange={handleInputChange}
                    />
                    <InputField
                        label="Display Limit"
                        name="displayLimit"
                        placeholder="Enter Display Limit"
                        value={layoutSection.displayLimit}
                        onChange={handleInputChange}
                    />

                    <div className="form-group mb-3 row">
                        <label className="col-lg-4 col-form-label">
                            Card Color <span className="text-danger">*</span>
                        </label>
                        <div className="col-lg-6">
                            <Select
                                options={cardColorOptions}
                                value={layoutSection.sectionCardColor}
                                onChange={handleCardColorChange}
                                classNamePrefix="select"
                            />
                        </div>
                    </div>
                </Card.Body>
            </Card>

            <Button disabled={disableSave} onClick={handleSave} className="mt-3">
                Save
            </Button>
        </div>
    );
};

export default StyleTwelve;
