import React, { useEffect, useState } from "react";
import { Table, Pagination, Form, Button, Row, Col, Badge } from "react-bootstrap";
import { BaseURL } from "../../../../Helper/config";
import { Link } from "react-router-dom";

const OrderDashboard = () => {
  const [orders, setOrders] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [search, setSearch] = useState("");
  const [filters, setFilters] = useState({ orderStatus: "" });
  const [sortField, setSortField] = useState("createdAt");
  const [sortOrder, setSortOrder] = useState("desc");
  const [loading, setLoading] = useState(false);

  const pageSize = 10; // Number of items per page

  const fetchOrders = async () => {
    setLoading(true);

    try {
      const queryParams = new URLSearchParams({
        search,
        filters: JSON.stringify(filters),
        page: currentPage,
        limit: pageSize,
        sortField,
        sortOrder,
      });

      const response = await fetch(`${BaseURL}/get-orders-admin?${queryParams}`);
      const data = await response.json();

      if (data.status === "Success") {
        setOrders(data.data);
        setTotalPages(data.pagination.totalPages);
      }
    } catch (error) {
      console.error("Error fetching orders:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchOrders();
  }, [search, filters, currentPage, sortField, sortOrder]);

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
    setCurrentPage(1); // Reset to the first page on new search
  };

  const handleFilterChange = (e) => {
    setFilters((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
    setCurrentPage(1); // Reset to the first page on new filters
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleSort = (field) => {
    if (sortField === field) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortField(field);
      setSortOrder("asc");
    }
  };

  return (
    <div className="container py-4">
      <h1 className="mb-4">Order Dashboard</h1>

      {/* Search and Filters */}
      <Row className="mb-3">
        <Col md={6}>
          <Form.Control
            type="text"
            placeholder="Search by Order Number, Kitchen Name, or Rider Name"
            value={search}
            onChange={handleSearchChange}
            className="shadow-sm"
          />
        </Col>
        <Col md={6}>
          <Form.Select
            name="orderStatus"
            value={filters.orderStatus}
            onChange={handleFilterChange}
            className="shadow-sm"
          >
            <option value="">Filter by Order Status</option>
            <option value="Pending">Pending</option>
            <option value="Completed">Completed</option>
            <option value="Accepted By Seller">Accepted By Seller</option>
            <option value="Procesed and Ready to pickUp">Procesed and Ready to pickUp</option>

            <option value="Accepted By Rider">Accepted By Rider</option>

            <option value="Cancelled">Cancelled</option>
            <option value="Paid">Paid</option>
          </Form.Select>
        </Col>
      </Row>

      {/* Orders Table */}
      <Table bordered hover responsive className="shadow-sm">
        <thead>
          <tr>
            <th onClick={() => handleSort("orderNumber")} style={{ cursor: "pointer" }}>
              Order Number {sortField === "orderNumber" && (sortOrder === "asc" ? "↑" : "↓")}
            </th>
            <th>Kitchen Name</th>
            <th>Rider Name</th>
            <th onClick={() => handleSort("orderStatus")} style={{ cursor: "pointer" }}>
              Order Status {sortField === "orderStatus" && (sortOrder === "asc" ? "↑" : "↓")}
            </th>
            <th onClick={() => handleSort("paymentStatus")} style={{ cursor: "pointer" }}>
              Payment Status {sortField === "paymentStatus" && (sortOrder === "asc" ? "↑" : "↓")}
            </th>
            <th onClick={() => handleSort("createdAt")} style={{ cursor: "pointer" }}>
              Date {sortField === "createdAt" && (sortOrder === "asc" ? "↑" : "↓")}
            </th>
            <th>Action</th>

          </tr>
        </thead>
        <tbody>
          {loading ? (
            <tr>
              <td colSpan="6" className="text-center">
                Loading...
              </td>
            </tr>
          ) : orders.length > 0 ? (
            orders.map((order) => (
              <tr key={order.orderNumber}>
                {
                    console.log(order.riderName,order.kitchenName)
                }
                <td>{order.orderNumber}</td>
                <td>{order.kitchenName}</td>
                <td>{order.riderName}</td>
                <td>
                  <Badge bg="secondary">{order.orderStatus}</Badge>
                </td>
                <td>
                  <Badge bg="info">{order.paymentStatus}</Badge>
                </td>
                <td>{new Date(order.createdAt).toLocaleDateString()}</td>
                <td>
                  <Link variant="info"  to={`/orderDetails/${order?._id}`}>
                    View Details
                  </Link>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="6" className="text-center">
                No orders found.
              </td>
            </tr>
          )}
        </tbody>
      </Table>

 
      <Pagination className="justify-content-center">
        {[...Array(totalPages).keys()].map((page) => (
          <Pagination.Item
            key={page + 1}
            active={page + 1 === currentPage}
            onClick={() => handlePageChange(page + 1)}
          >
            {page + 1}
          </Pagination.Item>
        ))}
      </Pagination>
    </div>
  );
};

export default OrderDashboard;
