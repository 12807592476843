import React, { useState, useEffect } from "react";
import { Row, Card, Dropdown, Col } from "react-bootstrap";
import StyleEight from "./styleEight";
import StyleFive from "./styleFive";
import StyleFour from "./styleFour";
import StyleOne from "./styleOne";
import StyleSeven from "./styleSeven";
import StyleSix from "./styleSix";
import StyleNine from "./styleNine";

import StyleTwo from "./styleTwo";
import StyleOneImage from "../../../images/Dropdown/Style1.png";
import StyleTwoImage from "../../../images/Dropdown/Style2.png";
import StyleThreeImage from "../../../images/Dropdown/Style3.png";
import StyleFourImage from "../../../images/Dropdown/Style4.png";
import StyleFiveImage from "../../../images/Dropdown/Style5.png";
import StyleSixImage from "../../../images/Dropdown/Style6.png";
import StyleSevenImage from "../../../images/Dropdown/Style7.png";
import StyleEightImage from "../../../images/Dropdown/Style8.png";
import StyleNineImage from "../../../images/Dropdown/Style9.png";
import CircleTitle from "./circleTitle";
import StyleTen from "./styleTen";
import Style10 from "../../../images/Dropdown/Style10.png";
import StyleEleven from "../../../images/Dropdown/Style11.png";
import StyleTwelveImage from "../../../images/Dropdown/Style12.png";

import Style11 from "./Style11";
import StyleTwelve from "./StyleTwelve";
import StyleThirteen from "./StyleThirteen";
const HomepageDesign = () => {
  const [customStyle, setCustomStyle] = useState("Select Style");

  return (
    <div>
      <h3>Design Your Home Page Layout </h3>
      <div>
        <Row>
          <Col>
            <Card>
              <Card.Header className="d-block">
                <Card.Title>Select Design Layout</Card.Title>
                <Card.Text className="m-0 subtitle">
                  Select Your Design Layout and Fill Your Section Data
                </Card.Text>
              </Card.Header>
              <Card.Body>
                <div className="basic-dropdown">
                  <Dropdown>
                    <Dropdown.Toggle variant="primary">
                      {customStyle}
                    </Dropdown.Toggle>
                    <Dropdown.Menu
                      style={{ height: "500px", overflow: "scroll" }}
                    >
                      <Dropdown.Item
                        onClick={() => {
                          setCustomStyle("Style One (Two Column 4 Foods)");
                        }}
                      >
                        Style One (Two Column 4 Foods)
                        <img src={StyleOneImage} />
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          setCustomStyle("Style Two (Two Column 4 Foods)");
                        }}
                      >
                        Style Two (Two Column 4 Foods)
                        <img src={StyleTwoImage} />
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          setCustomStyle("Style Three Circle Layout");
                        }}
                      >
                        Style Three Circle Layout
                        <img src={StyleThreeImage} style={{ marginLeft: 55 }} />
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          setCustomStyle("Style Four Food Four Categories");
                        }}
                      >
                        Style Four Food Four Categories
                        <img src={StyleFourImage} />
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          setCustomStyle("Style Five Four By Two Food ");
                        }}
                      >
                        Style Five Four By Two Food
                        <img src={StyleSixImage} style={{ marginLeft: 35 }} />
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          setCustomStyle("Style Six Carousel");
                        }}
                      >
                        Style Six Carousel
                        <img src={StyleSevenImage} style={{ marginLeft: 95 }} />
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          setCustomStyle("Style Seven Three Column");
                        }}
                      >
                        Style Seven Style Three Column
                        <img src={StyleEightImage} />
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          setCustomStyle("Style Eight One Column by Four");
                        }}
                      >
                        Style Eight One Column by Four
                        <img src={StyleFiveImage} />
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          setCustomStyle("Style Nine All Product");
                        }}
                      >
                        Style Nine All Product
                        <img src={StyleNineImage} style={{ marginLeft: 90 }} />
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          setCustomStyle("Style Ten Food Dairy");
                        }}
                      >
                        Style Ten Food Dairy
                        <img src={Style10} style={{ marginLeft: 90 }} />
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          setCustomStyle("StyleEleven-Recipe");
                        }}
                      >
                        Style Eleven
                        <img
                          src={StyleEleven}
                          style={{ marginLeft: 200, height: "32px" }}
                        />
                      </Dropdown.Item>



                      <Dropdown.Item
                        onClick={() => {
                          setCustomStyle("StyleTwelve-Food");
                        }}
                      >
                        Style Twelve -Food Card
                        <img
                          src={StyleTwelveImage}
                          style={{ marginLeft: 200, height: "32px" }}
                        />
                      </Dropdown.Item>


                      <Dropdown.Item
                        onClick={() => {
                          setCustomStyle("StyleThirteen-Banner");
                        }}
                      >
                        Banner
                        <img
                          src={StyleTwelveImage}
                          style={{ marginLeft: 200, height: "32px" }}
                        />
                      </Dropdown.Item>



                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
      {customStyle === "Select Style" ? (
        <div style={{ marginTop: 150 }}> </div>
      ) : (
        <div>
          {customStyle === "Style One (Two Column 4 Foods)" ? <StyleOne /> : ""}
          {customStyle === "Style Two (Two Column 4 Foods)" ? <StyleTwo /> : ""}
          {customStyle === "Style Three Circle Layout" ? <CircleTitle /> : ""}
          {customStyle === "Style Four Food Four Categories" ? (
            <StyleFour />
          ) : (
            ""
          )}
          {customStyle === "Style Five Four By Two Food " ? <StyleFive /> : ""}
          {customStyle === "Style Six Carousel" ? <StyleSix /> : ""}
          {customStyle === "Style Seven Three Column" ? <StyleSeven /> : ""}
          {customStyle === "Style Eight One Column by Four" ? (
            <StyleEight />
          ) : (
            ""
          )}
          {customStyle === "Style Nine All Product" ? <StyleNine /> : ""}
          {customStyle === "Style Ten Food Dairy" ? <StyleTen /> : ""}
          {customStyle === "StyleEleven-Recipe" ? <Style11 /> : ""}

          {
            customStyle === "StyleTwelve-Food" ? <StyleTwelve /> : ""
          }
          {

            customStyle === "StyleThirteen-Banner" ? <StyleThirteen /> : ""


          }
        </div>
      )}
    </div>
  );
};

export default HomepageDesign;
