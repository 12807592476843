import React, { useState, useEffect } from "react";
import { AiFillInteraction } from "react-icons/ai";
import {
  Row,
  Card,
  Button,
  Dropdown,
  DropdownButton,
  ButtonGroup,
} from "react-bootstrap";
import axios from "axios";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";
import { Base_Url } from "../../../misc";

import MyPagination from "../Pagination/MyPagination";
import { AiFillEye } from "react-icons/ai";
import TransactionDetailsModel from "./TransactionDetailsModel";
import PageTitle from "../../layouts/PageTitle";

const WithDrawList = () => {
  const [withDrawList, setwithDrawList] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [PAGE_SIZE, setPAGE_SIZE] = useState(20);
  const [totalItem, setTotalItem] = useState(0);
  const [filter1, setFilter1] = useState("");
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [show, setShow] = useState(false);
  const [transactionDetails, setHandleTransactionDetails] = useState({});

  // Add an effect to update window width on resize
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Clean up the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    setCurrentPage(1);
    let filtered = withDrawList.filter((item) => item?.withdrwalAmount);

    // Check if there are no matching results and searchTerm is not empty
    if (searchTerm.trim() !== "" && filtered.length === 0) {
      filtered = [];
    }

    setFilteredData(filtered);
  }, [searchTerm, withDrawList, filter1]);

  const handlesort = (value) => {
    if (value == "all") {
      // setfilter1("All");
      setFilteredData([...withDrawList]);
      // setfilter1("All");
    } else if (value === "active") {
      // setfilter1("Active");
      const sortedItems = withDrawList.filter((item) => item?.status === true);

      setFilteredData(sortedItems);
      // setfilter1("Active");
    } else if (value === "inactive") {
      // setfilter1("InActive");
      const sortedItems = withDrawList.filter((item) => item?.status === false);

      setFilteredData(sortedItems);
      // setfilter1("InActive");
    }
  };

  useEffect(() => {
    axios
      .get(`${Base_Url}/get-withdrawals`)

      .then((res) => {
        setwithDrawList(res.data.data);
        console.log(withDrawList);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const comment = async (_id) => {
    await Swal.fire({
      title: "Add a comment",
      text: "You won't be able to revert this!",
      input: "textarea",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Add it!",
    }).then((result) => {
      if (result.isConfirmed) {
        console.log(result);

        // console.log(blockNote);
        handleComment(_id, result.value);
      }
    });
  };

  const handleComment = (id, message) => {
    axios
      .post(`${Base_Url}/update-withDrawList/${id}`, {
        comment: message,
      })
      .then((res) => {
        console.log(res.data.data.modifiedCount);
        if (res.data.data.modifiedCount > 0) {
          const updateData = withDrawList.filter((r) => r._id !== id);
          setwithDrawList(updateData);
          Swal.fire({
            icon: "success",
            title: "Comment added Successfully!!",
            showConfirmButton: false,
            timer: 1000,
          });
        } else
          Swal.fire({
            icon: "warning",
            title: "Something Went Wrong. Try Again",
            showConfirmButton: false,
            timer: 150,
          });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleDelete = (id) => {
    axios
      .delete(`${Base_Url}/delete-withdrawals/${id}`)
      .then((res) => {
        console.log(res.data.data.deletedCount);
        if (res.data.data.deletedCount === 1) {
          const UpdateData = withDrawList.filter((r) => r._id !== id);
          setwithDrawList(UpdateData);
          Swal.fire("Deleted!", "Your file has been deleted.", "success");
        } else {
          Swal.fire({
            icon: "warning",
            title: "Somethig Want Wrong Try Again",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      })
      .catch((Err) => {
        console.log(Err);
      });
  };

  const del = (_id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        handleDelete(_id);
      }
    });
  };
  const handleApproval = (id) => {
    Swal.fire({
      // title: "Are you give  Approval for food delivery?",
      // text: "You won't be able to revert this!",
      icon: "success",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .post(`${Base_Url}/update-withdrawal/${id}`, {
            status:1,
          })
          .then((res) => {
            console.log(res.data.data.modifiedCount);
            if (res.data.data.modifiedCount > 0) {
              const UpdateData = withDrawList.filter((r) => r._id !== id);
              filteredData(UpdateData);
              Swal.fire({
                icon: "success",
                title: "withDrawList Approved SuccessFully!!",
                showConfirmButton: false,
                timer: 1000,
              });
            } else
              Swal.fire({
                icon: "warning",
                title: "Somethig Want Wrong Try Again",
                showConfirmButton: false,
                timer: 150,
              });
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  };

  // Pagination Logic
  const indexOfLastItem = currentPage * PAGE_SIZE;
  const indexOfFirstItem = indexOfLastItem - PAGE_SIZE;
  const currentItems = (
    filteredData.length > 0 ? filteredData : withDrawList
  ).slice(indexOfFirstItem, indexOfLastItem);
  console.log(filteredData, currentItems);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const totalPages = Math.ceil(
    (filteredData.length > 0 ? filteredData?.length : withDrawList?.length) /
      PAGE_SIZE
  );

  const HandleTransactionDetails = (data) => {
    setHandleTransactionDetails(data);
    console.log(data);
    setShow(true);
  };

  return (
    <>
      <PageTitle activeMenu="Withdraw List" motherMenu="Withdraw List" />{" "}
      <TransactionDetailsModel
        data={transactionDetails}
        show={show}
        setShow={setShow}
      />
      <div className="card h-auto" style={{ marginTop: 10 }}>
        <div className="card-body p-0">
          <div className="table-responsive">
            {withDrawList.length === 0 ? (
              ""
            ) : (
              <table
                className="table table-list i-table style-1 mb-4 border-0"
                id="guestTable-all3"
              >
                <thead>
                  <tr>
                    <th>No.</th>
                    <th>Withdrawals Amount</th>
                    <th>Balance During Withdrawal</th>
                    <th>Currency</th>
                    <th>RejectReasons</th>
                    <th>Status</th>
                    <th>Created By</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredData.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>
                          <div className="media-bx d-flex py-3  align-items-center">
                            <div>
                              <span className="mb-0">
                                {item?.withdrwalAmount}
                              </span>
                              {/* <p className="mb-0">1x </p>  */}
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="media-bx d-flex py-3  align-items-center">
                            <div>
                              <span className="mb-0">
                                {item?.balanceDuringWithrawal}
                              </span>
                              {/* <p className="mb-0">1x </p>  */}
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="media-bx d-flex py-3  align-items-center">
                            <div>
                              <span className="mb-0">{item?.currency}</span>
                              {/* <p className="mb-0">1x </p>  */}
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="media-bx d-flex py-3  align-items-center">
                            <div>
                              <span className="mb-0">
                                {item?.RejectReasons}
                              </span>
                              {/* <p className="mb-0">1x </p>  */}
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="media-bx d-flex py-3  align-items-center">
                            <div>
                              <span className="mb-0">
                                <td>
                                  <div>
                                    {item?.status ? "Approved" : "Pending"}
                                  </div>
                                </td>
                              </span>
                            </div>
                          </div>
                        </td>

                        <td>
                          <div className="media-bx d-flex py-3  align-items-center">
                            <div>
                              <span className="mb-0">{item?.createdBy}</span>
                            </div>
                          </div>
                        </td>

                        <td>
                          <div className="d-flex gap-2">
                            <DropdownButton
                              as={ButtonGroup}
                              title={<AiFillInteraction />}
                              id="bg-nested-dropdown"
                            >
                              <Dropdown.Item eventKey="1">
                                {item.withDrawListApproval === true && (
                                  <div
                                    onClick={() => {}}
                                    style={{
                                      border: 0,
                                    }}
                                  >
                                    Deactivate
                                  </div>
                                )}

                                {item.withDrawListApproval === false && (
                                  <div
                                    onClick={() => {
                                      //  unBlock(item._id);
                                    }}
                                    style={{
                                      border: 0,
                                    }}
                                  >
                                    Activate
                                  </div>
                                )}
                              </Dropdown.Item>
                              <Dropdown.Item eventKey="2">
                                <div
                                  onClick={() => {
                                    del(item._id);
                                  }}
                                  style={{
                                    border: 0,
                                  }}
                                >
                                  Delete
                                </div>
                              </Dropdown.Item>

                              <Dropdown.Item eventKey="3">
                                <div
                                  onClick={() => {
                                    comment(item._id);
                                  }}
                                  style={{
                                    border: 0,
                                  }}
                                >
                                  Comment
                                </div>
                              </Dropdown.Item>

                              <Dropdown.Item eventKey="4">
                                <div
                                  onClick={() => {
                                    handleApproval(item._id);
                                  }}
                                  style={{
                                    border: 0,
                                  }}
                                >
                                  Approval
                                </div>
                              </Dropdown.Item>
                              <Dropdown.Item eventKey="5">
                                <Link
                                  to={`/withDrawListdetails/${item?._id}`}
                                  state={item}
                                >
                                  <div
                                    style={{
                                      border: 0,
                                    }}
                                  >
                                    View
                                  </div>
                                </Link>
                              </Dropdown.Item>
                              <Dropdown.Item eventKey="6">
                                <Button
                                  onClick={() => HandleTransactionDetails(item)}
                                >
                                  <div
                                    style={{
                                      border: 0,
                                    }}
                                  >
                                    Transaction Details
                                  </div>
                                </Button>
                              </Dropdown.Item>
                            </DropdownButton>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            )}
            <MyPagination
              currentPage={currentPage}
              totalPages={totalPages}
              paginate={paginate}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default WithDrawList;
