import React from 'react';

const OImageField = ({
  value,
  onFileChange,
  onRemove,
  label,
  required = false,
  isEditable = true,
  className = "",
  placeholder = "Select an image",
  previewSize = {
    width: 200,
    height: 200
  },
  error
}) => {
  const [isEditing, setIsEditing] = React.useState(false);
  
  const handleImageChange = (e) => {
    const file = e.target.files?.[0];
    console.log(file);
    
    if (file) {
        onFileChange(file)
    //   const reader = new FileReader();
    //   reader.onloadend = () => {
    //     onChange?.(reader.result);
    //     setIsEditing(false);
    //   };
    //   reader.readAsDataURL(file);
    }
  };

  const handleRemove = () => {
    onRemove?.();
    setIsEditing(false);
  };

  const cardStyle = {
    border: '1px solid #dee2e6',
    borderRadius: '0.5rem',
    padding: '1rem',
    boxShadow: '0 2px 4px rgba(0,0,0,0.05)',
    marginBottom: '1rem'
  };

  const overlayStyle = {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0,0,0,0.4)',
    display: 'none',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '0.5rem',
    gap: '0.5rem'
  };

  const imageContainerStyle = {
    position: 'relative',
    ':hover': {
      [`& > div`]: {
        display: 'flex'
      }
    }
  };

  const placeholderStyle = {
    width: previewSize.width,
    height: previewSize.height,
    border: '2px dashed #dee2e6',
    borderRadius: '0.5rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#f8f9fa',
    color: '#6c757d',
    cursor: isEditable ? 'pointer' : 'default'
  };

  return (
    <div className={`form-group ${className}`}>
      {label && (
        <label className="form-label">
          {required && <span style={{ color: '#dc3545', marginRight: '0.25rem' }}>*</span>}
          {label}
        </label>
      )}

      <div style={cardStyle}>
        {!isEditing ? (
          <div className="d-flex flex-column align-items-center">
            {value ? (
              <div 
                className="position-relative mb-3"
                style={imageContainerStyle}
                onMouseEnter={(e) => {
                  if (isEditable) {
                    e.currentTarget.querySelector('.image-overlay').style.display = 'flex';
                  }
                }}
                onMouseLeave={(e) => {
                  if (isEditable) {
                    e.currentTarget.querySelector('.image-overlay').style.display = 'none';
                  }
                }}
              >
                <img
                  src={value}
                  alt="Preview"
                  style={{
                    width: previewSize.width,
                    height: previewSize.height,
                    objectFit: 'cover',
                    borderRadius: '0.5rem'
                  }}
                />
                {isEditable && (
                  <div className="image-overlay" style={overlayStyle}>
                    <button
                      className="btn btn-light btn-sm"
                      onClick={() => setIsEditing(true)}
                      style={{ marginRight: '0.5rem' }}
                    >
                      Edit
                    </button>
                    <button
                      className="btn btn-danger btn-sm"
                      onClick={handleRemove}
                    >
                      Remove
                    </button>
                  </div>
                )}
              </div>
            ) : (
              <div 
                style={placeholderStyle}
                onClick={() => isEditable && setIsEditing(true)}
              >
                {placeholder}
              </div>
            )}
          </div>
        ) : (
          <div className="d-flex flex-column">
            <input
              type="file"
              className="form-control mb-3"
              accept="image/*"
              onChange={handleImageChange}
              required={required}
            />
            <div className="d-flex justify-content-end">
              <button
                type="button"
                className="btn btn-secondary btn-sm"
                onClick={() => setIsEditing(false)}
              >
                Cancel
              </button>
            </div>
          </div>
        )}
      </div>

      {error && (
        <div className="invalid-feedback d-block">
          {error}
        </div>
      )}
    </div>
  );
};

export default OImageField;