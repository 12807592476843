export const MenuList = [
  {
    title: "Dashboard",
    // classsChange: "mm-collapse",
    iconStyle: "fa fa-door-open",
    to: "/",
  },
  {
    title: "Homepage",
    classsChange: "mm-collapse",
    iconStyle: "bi bi-shop-window",
    content: [
      {
        title: "Design",
        to: "homepagedesign",
      },
      {
        title: "Circle Layout",
        to: "Circle-Setting",
      },
      {
        title: "Edit Home Page",
        to: "editHomePage",
      },
      {
        title: "Edit Circle ",
        to: "EditCircleLayout",
      },
    ],
  },

  // Seller Management
  {
    title: "Manage Seller",
    classsChange: "mm-collapse",
    iconStyle: "bi bi-people",
    content: [
      {
        title: "Seller List",
        to: "all-seller",
      },

      {
        title: "Inactive List",
        to: "inactive-seller",
      },

      {
        title: "Pending Approval",
        to: "pending-seller",
      },

      {
        title: "Draft Approval",
        to: "draft-seller",
      },

      {
        title: "Approved Seller",
        to: "approved-seller",
      },
    ],
  },

  {
    title: "Manage Buyer",
    classsChange: "mm-collapse",
    iconStyle: "bi bi-people",
    content: [
      {
        title: "Buyer List",
        to: "user-list",
      },
      {
        title: "Inactive List",
        to: "inactive-buyer",
      },
    ],
  },

  {
    title: "Manage Rider",
    classsChange: "mm-collapse",
    iconStyle: "bi bi-people",
    content: [
      {
        title: "Rider List",
        to: "rider-list",
      },
      {
        title: "Pending Approval",
        to: "pending-rider",
      },
      {
        title: "Approved Rider",
        to: "approved-rider",
      },
    ],
  },

  {
    title: "Manage Food",
    classsChange: "mm-collapse",
    iconStyle: "bi bi-shop-window",
    content: [
      {
        title: "Food List",
        to: "food-list",
      },
      {
        title: "Create Category",
        to: "create-food-category",
      },

      {
        title: " Category List",
        to: "categoryList",
      },

      {
        title: "Create SubCategory",
        to: "create-SubCategory",
      },
      {
        title: "SubCategory List ",
        to: "SubCategoryList",
      },
    ],
  },

  {
    title: "Manage Recipe",
    classsChange: "mm-collapse",
    iconStyle: "bi bi-shop-window",
    content: [
      // {

      //   title: "ActiveSeller List",
      //   to: "ActiveSeller",
      // },

      // {
      //   title: "Pending Seller",
      //   to: "InactiveSeller",

      //   title: "Create Recipe",
      //   to: "create-recipe",

      // },
      {
        title: "Create Recipe",
        to: "Create_recipe",
      },
      {
        title: "Recipe List",
        to: "recipe-list",
      },
      {
        title: "Create category",
        to: "create-recipe-category",
      },
      {
        title: "Category list",
        to: "recipe-category-list",
      },
    ],
  },

  {
    title: "Manage Coupon",
    classsChange: "mm-collapse",
    iconStyle: "bi bi-shop-window",
    content: [
      {
        title: "Create Coupon",
        to: "create-coupon",
      },
      {
        title: "Coupon List",
        to: "coupon",
      },
    ],
  },

  {
    title: "Manage Withdraw",
    classsChange: "mm-collapse",
    iconStyle: "bi bi-shop-window",
    content: [
      {
        title: "Withdraw List",
        to: "withdraw-list",
      },
    ],
  },
  {
    title: "FoodBlog/Social",
    classsChange: "mm-collapse",
    iconStyle: "bi bi-bicycle",
    content: [
      {
        title: "Post How do we do",
        to: "howdowedo",
      },
      {
        title: "Post How do we do List",
        to: "HowdowedoList",
      },
      {
        title: "Post Food Dairy",
        to: "foodDairy",
      },
      {
        title: "All Food Dairy",
        to: "foodDairyList",
      },
    ],
  },

  {
    title: "Manage Order",
    classsChange: "mm-collapse",
    iconStyle: "bi bi-shop-window",
    content: [
      {
        title:"All Order",
        to:"all-order"
      },
      {
        title: "Pending Order",
        to: "pending-order",
      },
      {
        title: "Order Ready",
        to: "ready-order",
      },
      {
        title: "Assign Order",
        to: "assign-order",
      },
      {
        title: "Issue Order",
        to: "issue-order",
      },
    ],
  },

  //Rider Management
  // {
  //   title: "Rider Management",
  //   classsChange: "mm-collapse",
  //   iconStyle: "bi bi-people",
  //   content: [
  //     {
  //       title: "Rider List",
  //       to: "All-Rider",
  //     },
  //     {
  //       title: "Active Rider List",
  //       to: "ActiveSeller",
  //     },
  //     {
  //       title: "Inactive Rider",
  //       to: "InactiveSeller",
  //     },
  //   ],
  // },

  // category
  // {
  //   title: "Category",
  //   classsChange: "mm-collapse",
  //   iconStyle: "bi bi-shop-window",
  //   content: [
  //     {
  //       title: "Create Category",
  //       to: "createCategory",
  //     },
  //     {
  //       title: "Category List",
  //       to: "categoryList",
  //     },
  //     {
  //       title: "Pending Category",
  //       to: "pendingCategory",
  //     },
  //     {
  //       title: "Active Category",
  //       to: "ActiveCategory",
  //     },
  //   ],
  // },
  // {
  //   title: "Recipe Category",
  //   classsChange: "mm-collapse",
  //   iconStyle: "bi bi-shop-window",
  //   content: [
  //     {
  //       title: "Create Category",
  //       to: "createRecipeCategory",
  //     },
  //     {
  //       title: "Category List",
  //       to: "categoryList",
  //     },
  //     {
  //       title: "Pending Category",
  //       to: "pendingCategory",
  //     },
  //     {
  //       title: "Active Category",
  //       to: "ActiveCategory",
  //     },
  //   ],
  // },

  // {
  //   title: "SubCategory",
  //   classsChange: "mm-collapse",
  //   iconStyle: "bi bi-shop-window",
  //   content: [
  //     {
  //       title: "Create SubCategory",
  //       to: "create-SubCategory",
  //     },
  //     {
  //       title: "SubCategory List ",
  //       to: "SubCategoryList",
  //     },
  //     {
  //       title: "Pending SubCategory",
  //       to: "pendingSub-Category",
  //     },
  //     {
  //       title: "Active SubCategory",
  //       to: "ActiveSub-Category",
  //     },
  //   ],
  // },

  // {
  //   title: "FoodManagement",
  //   classsChange: "mm-collapse",
  //   iconStyle: "bi bi-shop-window",
  //   content: [
  //     {
  //       title: "Create Food",
  //       to: "create-food",
  //     },
  //     {
  //       title: "Food List",
  //       to: "FoodManagement",
  //     },
  //     {
  //       title: "Create Cupon",
  //       to: "CreateCoupon",
  //     },
  //   ],
  // },

  // {
  //   title: "FoodBlog/Social",
  //   classsChange: "mm-collapse",
  //   iconStyle: "bi bi-bicycle",
  //   content: [
  //     {
  //       title: "Create Recipe",
  //       to: "Create_recipe",
  //     },
  //     {
  //       title: "Recipe List",
  //       to: "recipeList",
  //     },
  //     {
  //       title: "Post How do we do",
  //       to: "howdowedo",
  //     },
  //     {
  //       title: "Post How do we do List",
  //       to: "HowdowedoList",
  //     },
  //     {
  //       title: "Post Food Dairy",
  //       to: "foodDairy",
  //     },
  //     {
  //       title: "All Food Dairy",
  //       to: "foodDairyList",
  //     },
  //   ],
  // },

  // {
  //   title: "Order",
  //   classsChange: "mm-collapse",
  //   iconStyle: "bi bi-shop-window",
  //   content: [
  //     {
  //       title: "Pending Order",
  //       to: "assignRider",
  //     },

  //   ],
  // },

  {
    title: "Banner",
    classsChange: "mm-collapse",
    iconStyle: "bi bi-shop-window",
    content: [
      {
        title: "Create Banner",
        to: "createBanner",
      },

      // {
      //   title: "Manage Delivery",
      //   classsChange: "mm-collapse",
      //   iconStyle: "bi bi-shop-window",
      //   content: [
      //     {
      //       title: "Delivery fee setup",
      //       to: "delivery-setup",
      //     },
      //   ],
      // },

      {
        title: "Service Area",
        classsChange: "mm-collapse",
        iconStyle: "bi bi-shop-window",
        content: [
          {
            title: "List of Service Area",
            to: "area-list",
          },
          {
            title: "Create Service Area",
            to: "create-area",
          },
        ],
      },

      // {
      //   title: "Manage Ticket",
      //   classsChange: "mm-collapse",
      //   iconStyle: "bi bi-shop-window",
      //   content: [
      //     {
      //       title: "Manage Ticket",
      //       to: "manage-ticket",
      //     },
      //   ],
      // },

      // {
      //   title: "Manage Finance",
      //   classsChange: "mm-collapse",
      //   iconStyle: "bi bi-shop-window",
      //   content: [
      //     {
      //       title: "List of Transaction",
      //       to: "list-transaction",
      //     },
      //   ],
      // },
      // {
      //   title: "Banner",
      //   classsChange: "mm-collapse",
      //   iconStyle: "bi bi-shop-window",
      //   content: [
      //     {
      //       title: "Create Banner",
      //       to: "createBanner",
      //     },
    ],
  },
  {
    title: "Service Area",
    classsChange: "mm-collapse",
    iconStyle: "bi bi-shop-window",
    content: [
      {
        title: "List of Service Area",
        to: "area-list",
      },
      {
        title: "Create Service Area",
        to: "create-service-area",
      },
    ],
  },
  // {
  //   title: "Banner",
  //   classsChange: "mm-collapse",
  //   iconStyle: "bi bi-shop-window",
  //   content: [
  //     {
  //       title: "Create Banner",
  //       to: "createBanner",
  //     },

  //     // {
  //     //   title: "Manage Delivery",
  //     //   classsChange: "mm-collapse",
  //     //   iconStyle: "bi bi-shop-window",
  //     //   content: [
  //     //     {
  //     //       title: "Delivery fee setup",
  //     //       to: "delivery-setup",
  //     //     },
  //     //   ],
  //     // },

  //   ],
  // },
  // {
  //   title: "Restaurant",
  //   classsChange: "mm-collapse",
  //   iconStyle: "bi bi-shop-window",
  //   content: [
  //     {
  //       title: "Restaurant",
  //       to: "restaurant",
  //     },
  //     {
  //       title: "Menu",
  //       to: "menu",
  //     },
  //     {
  //       title: "Orders",
  //       to: "orders",
  //     },
  //     {
  //       title: "Reviews",
  //       to: "customer-reviews",
  //     },
  //   ],
  // },
  // //Drivers
  // {
  //   title: "Drivers",
  //   classsChange: "mm-collapse",
  //   iconStyle: "bi bi-bicycle",
  //   content: [
  //     {
  //       title: "Dashboard",
  //       to: "deliver-main",
  //     },
  //     {
  //       title: "Orders",
  //       to: "deliver-order",
  //     },
  //     {
  //       title: "Feedback",
  //       to: "feedback",
  //     },
  //   ],
  // },

  // {
  //   title: "Blog ",
  //   classsChange: "mm-collapse",
  //   iconStyle: "bi bi-shop-window",
  //   content: [
  //     {
  //       title: "Write a Blog",
  //       to: "form-ckeditor",
  //     },
  //   ],
  // },
  {
    title: "Delivery Plans",

    classsChange: "mm-collapse",
    iconStyle: "bi bi-people",
    content: [
      {
        title: "Create Plan",
        to: "create-delivery-plans",
      },
      {
        title: "Plan List",
        to: "all-delivery-plan",
      },
    ],
  },

  {
    title: "Admin",

    classsChange: "mm-collapse",
    iconStyle: "bi bi-people",
    content: [
      {
        title: "Add Admin",
        to: "food-order",
      },
      {
        title: "Admin List",
        to: "admin-list",
      },
    ],
  },
];
