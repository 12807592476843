"use client";

import React from "react";
import { useFormContext, Controller } from "react-hook-form";

const OInput = ({
  name,
  type = "text",
  id,
  placeholder,
  label,
  required = false,
  className = "",
  value,
  defaultValue = "",
}) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const errorMessage = errors[name]?.message;

  return (
    <div className={`form-group mb-3 ${className}`}>
      {label && (
        <label htmlFor={id || name} className="form-label">
          {required && <span style={{ color: "red" }}>*</span>} {label}
        </label>
      )}
      <Controller
        control={control}
        name={name}
        defaultValue={defaultValue}
        render={({ field }) => (
          <input
            {...field}
            id={id || name}
            type={type}
            value={value ?? field.value}
            placeholder={placeholder}
            required={required}
            className={`form-control ${errors[name] ? "is-invalid" : ""}`}
          />
        )}
      />
      {errorMessage && (
        <div className="invalid-feedback">{errorMessage}</div>
      )}
    </div>
  );
};

export default OInput;
